<template>
  <div class="block">
    <H5Navheader :name="'区块数据'" />
    <div class="main-body">
      <div class="block-card">
        <div class="bg-image"></div>
        <div class="content">
          <div class="title">区块详情</div>
          <div style="display:inline-block">
            <div class="hex-card">
              <div class="label">区块哈希</div>
              <div class="value">{{'000000107375bee56191e9a2d3ef830c2a8797035d90ebf8822281de8150d7d2'}}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">区块高度</div>
            <div class="value">297731</div>
          </div>
          <div class="item">
            <div class="label">默克尔树</div>
            <div class="value">076fddda4caed3d791fbe95f74bc6cbceeddba431a07b2d6c63755713695ced7</div>
          </div>
          <div class="item">
            <div class="label">区块大小</div>
            <div class="value">490</div>
          </div>
          <div class="item">
            <div class="label">确认数量</div>
            <div class="value">26</div>
          </div>
          <div class="item">
            <div class="label">区块时间</div>
            <div class="value">2023-02-28 10:41:57</div>
          </div>
          <div class="item">
            <div class="label">区块难度</div>
            <div class="value">0.05192947702060222</div>
          </div>
          <div class="item">
            <div class="label">Bits</div>
            <div class="value">1d1341b0</div>
          </div>
        </div>
      </div>
      <div class="table-wrap-pc" style="padding-bottom: 124px">
        <div class="title">区块记录明细</div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column prop="blockNumber" label="ID" width="160" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <router-link to="/address">{{scope.row.blockNumber}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="blockHash" label="数据哈希" width="250">
            <!-- <template slot-scope="scope">
              <router-link to="/hexDetail">{{scope.row.name}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="count" label="区块哈希" width="250"> </el-table-column>
          <el-table-column prop="createTime" label="区块高度"> </el-table-column>
          <el-table-column prop="createTime" label="生成时间"> </el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="text" icon="el-icon-edit" @click="t(scope.row.id)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total > 0" :total="total" :page.sync="params.pageNum" :limit.sync="params.pageSize"
          @pagination="getList" />
      </div>
      <div class="list-wrap-h5">
        <div class="title">订单数据</div>
        <div class="card-item" v-for="item,idx in 10" :key="idx">
          <div class="hex">
            <div class="text">
              {{'6fb176e5e642b68c1805627c0dc6e8e05f3da5c101977aca49fec43a79195898'}}
            </div>
            <div class="title">ID</div>
          </div>
          <div class="info">
            <div class="info-item">
              <div class="value">{{'297728'}}</div>
              <div class="title">区块高度</div>
            </div>
            <div class="info-item">
              <div class="value">{{'2020/1/11 下午2:13:04'}}</div>
              <div class="title">生成时间</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import H5Navheader from '@/components/H5Navheader'
export default {
  components: {
    H5Navheader,
  },
  data() {
    return {
      total: 100,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
    }
  },
  methods: {
    getList() {},
  },
}
</script>

<style lang='scss' scoped>
.block {
  .block-card {
    background: #110605;
    border: 1px solid #FF4630;
    position: relative;
    @media screen and (min-width: 1200px) {
      padding: 30px 30px 10px 30px;
      margin-bottom: 45px;
      .bg-image {
        position: absolute;
        width: 325px;
        height: 283px;
        background-image: url('../../assets/blockdetails_bg.png');
        background-size: contain;
        right: 87px;
        top: 47px;
      }
      > .title {
        font-size: 20px;
        font-weight: bold;
      }
      .hex-card {
        display: flex;
        padding: 15px 23px 15px 13px;
        background: linear-gradient(90deg, #FF4630, #FAFFE5);
        border-radius: 13px;
        align-items: center;
        margin-bottom: 23px;
        margin-top: 20px;
        .label {
          font-size: 14px;
          font-weight: bold;
          color: #18184c;
          padding-right: 26px;
        }
        .value {
          font-size: 12px;
          color: #18184c;
        }
      }
      .item {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        .label {
          font-size: 14px;
          color: #ababff;
          width: 60px;
          padding-right: 10px;
          line-height: 14px;
        }
        .value {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      padding: 0.3rem 0.3rem 0.1rem 0.3rem;
      margin: 0 0.24rem;
      border-radius: 0.04rem;
      margin-bottom: 0.7rem;
      .bg-image {
        position: absolute;
        width: 3.75rem;
        height: 3.27rem;
        background-image: url('../../assets/h5_blockdetails_bg.png');
        background-size: contain;
        right: 0.04rem;
        bottom: 0.21rem;
      }
      > .title {
        font-size: 0.32rem;
        font-weight: bold;
      }
      .hex-card {
        width: 100%;
        word-break: break-all;
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #FF4630, #FAFFE5);
        border-radius: 0.18rem;
        padding: 0.2rem 0.24rem 0.2rem 0.2rem;
        width: 6.42rem;
        box-sizing: border-box;
        margin: 0.2rem 0 0.32rem 0;
        .label {
          font-size: 0.24rem;
          font-weight: bold;
          color: #18184c;
          width: 0.5rem;
          flex-shrink: 0;
          margin-right: 0.2rem;
        }
        .value {
          font-size: 0.2rem;
          color: #18184c;
        }
      }
      .item {
        width: 100%;
        display: flex;
        // align-items: center;
        font-size: 0.2rem;
        padding-bottom: 0.2rem;
        position: relative;
        z-index: 2;
        .label {
          color: #ababff;
          width: 1rem;
          padding-right: 0.2rem;
          flex-shrink: 0;
          line-height: 0.24rem;
        }
        .value {
          word-break: break-all;
        }
      }
    }
  }
  .list-wrap-h5 {
    padding: 0.3rem 0;
    > .title {
      display: inline-block;
      font-size: 0.28rem;
      padding: 0.29rem 0.93rem;
      border-bottom: 1px solid #FF4630;
    }
    .card-item {
      padding: 0.3rem;
      margin: 0.3rem 0.24rem;
      &:nth-child(even) {
        background: #110605;
      }
      &:nth-child(odd) {
        background: #0B0202;
      }
      .hex {
        .text {
          word-break: break-all;
          font-size: 0.2rem;
          color: #FF4630;
        }
        .title {
          font-size: 0.2rem;
          color: #ababff;
          padding: 0.14rem 0 0.38rem 0;
        }
      }
      .info {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .info-item {
          padding-right: 1.16rem;
          .value {
            font-size: 0.24rem;
            line-height: 0.24rem;
          }
          .title {
            font-size: 0.2rem;
            color: #ababff;
            padding-top: 0.1rem;
          }
        }
      }
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
</style>